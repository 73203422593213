import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#F7F8F8',
    color100: '#EFF0F0',
    color200: '#E0E1E1',
    color300: '#D0D2D1',
    color400: '#C1C3C2',
    color500: '#B1B4B3',
    color600: '#8E908F',
    color700: '#6A6C6B',
    color800: '#474848',
    color900: '#232424'
  },
  default: {
    color50: '#D9DDE4',
    color100: '#B3BCC9',
    color200: '#8E9AAF',
    color300: '#687894',
    color400: '#425779',
    color500: '#1C355E',
    color600: '#162A4B',
    color700: '#112038',
    color800: '#0B1526',
    color900: '#060B13'
  },
  accent: {
    color50: '#FFF7E7',
    color100: '#FFF0CE',
    color200: '#FFE8B6',
    color300: '#FEE09E',
    color400: '#FED985',
    color500: '#FED16D',
    color600: '#CBA757',
    color700: '#987D41',
    color800: '#66542C',
    color900: '#332A16'
  },
  positive: {
    color50: '#E1EDE5',
    color100: '#C3DBCB',
    color200: '#A5CAB1',
    color300: '#86B896',
    color400: '#68A67C',
    color500: '#4a9462',
    color600: '#3B764E',
    color700: '#1A5632',
    color800: '#1E3B27',
    color900: '#0F1E14'
  },
  negative: {
    color50: '#FBE6EB',
    color100: '#F7CCD6',
    color200: '#EE99AD',
    color300: '#E66684',
    color400: '#DD335B',
    color500: '#D50032',
    color600: '#AA0028',
    color700: '#80001E',
    color800: '#550014',
    color900: '#2B000A'
  },
  special: {
    color50: '#E3F6FB',
    color100: '#C6EDF7',
    color200: '#AAE4F4',
    color300: '#8DDAF0',
    color400: '#71D1EC',
    color500: '#32788B',
    color600: '#43A0BA',
    color700: '#32788B',
    color800: '#22505D',
    color900: '#11282E'
  }
};

const variant: ACPVariant = {
  id: 'www.azulos.com',
  productTitle: 'Azulos Prepaid Account',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'azulos',
    topLevelDomain: 'com'
  },
  colors,
  themeConfiguration: {
    acpAppBarV2: {
      backgroundColor: colors.default.color500
    }
  },
  legacyAcpTheme: 'azulos',
  legacyAcpBrandName: 'azulos',
  legacyLegosTheme: 'azulos',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  contactInfo: {
    name: 'Azulos Prepaid',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'contact@azulos.com',
    fax_formatted: '(512) 857-0263',
    phone_formatted: '1-800-865-0782',
    phone: '8008650782',
    phone_international: '737-220-8956',
    balance_check_phone: '18008650782',
    balance_check_phone_formatted: '1-800-865-0782',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '',
      appsFlyerBannerKey: '007cd629-7d23-4cd3-81a1-4b12e65932ef',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: false,
      showScanCardRegistration: true,
      showMarketingSiteLink: false,
      showFreeAtmFinderLink: false,
      showWUTransfers: false,
      showReloadLocationLink: false
    },
    misc: {
      distributor: 'amscotafs',
      layoutComponentOverride: false,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: colors.default.color500,
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: colors.accent.color500,
      marketingSiteUrl: 'https://www.azulos.com',
      marketingSiteLinkText: 'Azulos.com',
      locationSearchMax: '25',
      iOSAppId: '1534694622'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today.",
      copyrightStatement:
        'Ouro Global, Inc. All rights reserved. All trademarks and service marks belong to their owners.'
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'bank-transfers',
      'handoff',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials'
    ]
  }
};

export default variant;
